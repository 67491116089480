<template>
  <v-container :class="random_bg" fluid>
    <v-row class="justify-center">
      <v-card width="360" height="300" class="mt-12 ma-5">
        <!-- <v-img src="@/assets/login_header.jpg" height="200px" class="white--text align-end">
          <v-card-title primary-title class="pl-5">Please login to system</v-card-title>
        </v-img> -->
        <!-- <v-img :src="random_image | imageUrl" height="220" class="white--text align-end">
          <v-card-title class="pl-5 subtitle-1 pb-1">Please login to system</v-card-title>
        </v-img> -->
        <v-card-title>
          <h3 class="subtitle-1">Please login to system</h3>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form" @submit.prevent="submit">
            <!-- username -->
            <v-text-field
              name="username"
              label="Username"
              id="username"
              v-model="account.username"
              :rules="[() => !!account.username || 'กรุณากรอก']"
            ></v-text-field>
            <!-- password -->
            <v-text-field
              name="password"
              label="password"
              id="password"
              :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
              @click:append="isShowPassword = !isShowPassword"
              :type="isShowPassword ? 'text' : 'password'"
              :rules="[() => !!account.password || 'กรุณากรอก']"
              counter
              v-model="account.password"
            ></v-text-field>
            <!-- button -->
            <v-row class="justify-space-between px-3 pt-5">
              <!-- <v-btn text @click.prevent="$router.push('/register')">Register</v-btn> -->
              <v-spacer></v-spacer>
              <v-btn type="submit" class="default">Login</v-btn>
            </v-row>

            <v-row class="px-3 pt-5 justify-center">
              <span class="red--text">{{
                $store.state.loginStatusMessage
              }}</span>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api"

export default {
  name: "Login",
  data() {
    return {
      valid: true,
      isShowPassword: false,
      account: {
        username: "",
        password: "",
      },
      //dataImages: ['docks.jpg','docks2.jpg', "docks3.jpg", "docks4.jpg", "docks5.jpg", "docks6.jpg", "docks7.jpg"],
      dataBg: ["bg", "bg2", "bg3", "bg4","bg5","bg6","bg7","bg8","bg9","bg10"],
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // เรียก store ให้ทำงาน
        this.$store.dispatch({
          type: "doLogin",
          username: this.account.username,
          password: this.account.password
        })
      }
    },
  },
  computed: {
    // random_image() {
    //     return this.dataImages[Math.floor(Math.random()*this.dataImages.length)] + "?" + Math.floor(Date.now() / 1000);
    // },
    random_bg() {
      let c =
        "bg-main " + this.dataBg[Math.floor(Math.random() * this.dataBg.length)]
      //console.log(c);
      return c
    },
  },
  // การทำ Lifecycle hooks
  created() {
    // ไว้สำหรับ initial ค่าเริ่มติม ก่อน DOM จะทำการสร้าง
  },
  beforeMount() {
    //จะทำงานก่อนที่ DOM จะถูกสร้างขึ้นมา
  },
  mounted() {
    // เมื่อ DOM ถูกสร้างขึ้นมาเรียบร้อยแล้ว และสามารถเข้าถึง DOM ได้  hook mounted() นี้จะเริ่มทำงาน
    if (api.isLoggedIn()) {
      this.$router.push("/order")
    }
  },
  beforeUpdate() {
    // เมื่อมีการอัพดเดต state method นี้จะทำงาน
  },
  updated() {
    // เมื่อมีการอัพดเดต state method นี้จะทำงานแต่ beforeUpdate()จะทำงานก่อน
  },
  beforeDestroy() {
    // ไว้เคลียร์ค่า ที่จุดนี้ เช่น clear ค่าคืนให้กับ memory
    //console.log("before destroy")
    // if (typeof this.$store.state.loginStatusMessage !== 'undefined') {
    //     this.$store.state.loginStatusMessage = "";
    // }
    this.$store.dispatch({
      type: "clearLoginMessage",
    })
  },
  destroyed() {},
}
</script>

<style scoped>
body,
html {
  height: 100%;
}

.bg-main {
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg {
  background-image: url("~@/assets/nbgj1.jpg");
}

.bg2 {
  background-image: url("~@/assets/nbgj2.jpg");
}

.bg3 {
  background-image: url("~@/assets/nbgj3.jpg");
}

.bg4 {
  background-image: url("~@/assets/nbgj4.jpg");
}
.bg5 {
  background-image: url("~@/assets/nbgj5.jpg");
}
.bg6 {
  background-image: url("~@/assets/nbgj6.jpg");
}
.bg7 {
  background-image: url("~@/assets/nbgj7.jpg");
}
.bg8 {
  background-image: url("~@/assets/nbgj8.jpg");
}
.bg9 {
  background-image: url("~@/assets/nbgj9.jpg");
}
.bg10 {
  background-image: url("~@/assets/nbgj10.jpg");
}
</style>
